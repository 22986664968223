import React, { useState } from 'react';
import { Copy, CheckCircle2 } from 'lucide-react';

const ResultDisplay = ({ result }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    try {
      const textarea = document.createElement('textarea');
      textarea.value = result;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="relative p-5 md:p-6 rounded-2xl bg-white/10 backdrop-blur-md border border-white/20">
      <button
        onClick={handleCopy}
        className="absolute top-4 right-4 p-3 rounded-xl bg-white/10 hover:bg-white/20 
                   transition-all duration-300 active:scale-[0.97] touch-none"
        style={{ WebkitTapHighlightColor: 'transparent' }}
      >
        {copied ? (
          <CheckCircle2 className="w-6 h-6 text-green-400" />
        ) : (
          <Copy className="w-6 h-6 text-blue-300" />
        )}
      </button>
      <div className="text-blue-50 whitespace-pre-wrap text-base md:text-lg pr-14">{result}</div>
    </div>
  );
}

export default ResultDisplay; 