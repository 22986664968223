import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { IonIcon } from "@ionic/react";
import { exit } from "ionicons/icons";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function SignOut() {
  const handleSignOut = async () => {
    await auth.signOut();
  };

  return (
    auth.currentUser && (
      <button className="ml-4 mt-2" onClick={handleSignOut}>
        <IonIcon className="signout-icon" icon={exit} />
      </button>
    )
  );
}

export default SignOut;
