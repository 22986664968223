import React from "react";

function Feedback() {
  return (
    <div className="fixed bottom-8 right-6">
      <a
        href="https://insigh.to/b/articulate"
        target="_blank"
        rel="noopener noreferrer"
        className="z-50 rounded-full bg-primary/80 px-4 py-3 text-white shadow-lg hover:bg-primary"
      >
        Feedback
      </a>
    </div>
  );
}

export default Feedback;
