import Tagline from "./Tagline";
import { roadmap } from "../constants";
import { check2, grid, loading1 } from "../assets";

function Roadmap() {
  return (
    <>
      <div id="roadmap-div" className="divider-info pt-8"></div>
      <section className="mt-4" id="roadmap">
        <div className="container">
          <h2 className="mb-4 text-center text-3xl font-bold md:text-5xl">
            What's In Progress?
          </h2>
          <div className="relative grid gap-6 md:grid-cols-2 md:gap-4 md:pb-[7rem]">
            {roadmap.map((item) => {
              const status = item.status === "done" ? "DONE" : "IN PROGRESS";

              return (
                <div
                  className={`p-0.25 rounded-[2.5rem] border border-primary border-opacity-40 md:flex even:md:translate-y-[7rem]`}
                  key={item.id}
                >
                  <div className="bg-n-8 xl:p-15 relative overflow-hidden rounded-[2.4375rem] p-8">
                    <div className="absolute left-0 top-0 max-w-full">
                      <img
                        className="w-full"
                        src={grid}
                        width={550}
                        height={550}
                        alt="Grid"
                      />
                    </div>
                    <div className="z-1 relative">
                      <div className="mb-8 flex max-w-[27rem] items-center justify-between md:mb-20">
                        <Tagline>{item.date}</Tagline>

                        <div className="flex items-center rounded-lg bg-accent bg-opacity-50 px-4 py-1 text-sm">
                          <div className="tagline text-indigo-100">
                            {status}
                          </div>
                        </div>
                      </div>

                      <div className="-mx-15 -my-10 mb-10">
                        <img
                          className="mx-auto my-20 w-2/3 lg:my-auto"
                          src={item.imageUrl}
                          width={628}
                          height={426}
                          alt={item.title}
                        />
                      </div>
                      <h4 className="h4 mb-4">{item.title}</h4>
                      <p className="body-2 text-n-4">{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="md:mt-15 mt-12 flex justify-center xl:mt-20">
            {/* <Button href="/roadmap">Our roadmap</Button> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Roadmap;
