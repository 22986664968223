import React from "react";
import { useState } from "react";
import HowItWorksMockup from "../HowItWorks/howItWorksMockup";

function HowItWorksAccordion() {
  const [mockup, setMockup] = useState(0);
  return (
    <>
      <div className="flex w-full flex-col justify-between gap-5 md:hidden">
        <HowItWorksMockup mockupSelected={mockup} />

        <div className="flex w-full flex-col gap-2">
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(0);
              }}
              type="radio"
              name="my-accordion-2"
              defaultChecked
            />
            <div className="collapse-title text-lg font-bold">
              Step 0: Sign Up and Install PWA
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                Sign up for an account and install the PWA on your phone to
                start your language journey with Articulate! Once the app is on
                the homescreen, you can use it like any other app, even offline!
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(1);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold">
              Step 1: Search Word or Phrase
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                Search for a word or phrase that you want to learn more about,
                and Articulate will provide you with a simplified definition,
                along with the formal definition, and showcase three practical
                example sentences. Additionally, you'll discover five related
                hashtags that offer further context and understanding.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(2);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 2: Swipe and Save to a Lexicon
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                After receiving the word/phrase card, you can swipe to send the
                card to your Surface or Deep Lexicon. Send words you want to use
                in daily conversation to the 'Surface' Lexicon, and words you
                want to store for future reference to the 'Deep' Lexicon.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(3);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 3: View Saved Words in Lexicons
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                You can view your saved words in the 'Surface' and 'Deep'
                Lexicons and press on a word card to view its definition and
                examples.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(4);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 4: Review Words in Surface Lexicon
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                On the Review page, you can view all the words in your 'Surface'
                Lexicon and press on a word you want to review. Here, you will
                try to type a sentence with the word and you will be given
                Feedback based on how well you used the word in context.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(5);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 5: Earn Points for Reviewing!
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                After reviewing a word in the Surface Lexicon, you will earn
                points based on how well you used the word in context. You can
                earn new badges as you accumulate points!
              </p>
            </div>
          </div>

          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(6);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 6: Check your Progress!
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                On the User Dashboard page, you can view your progress, see how
                many words you've learned, and how many badges you've collected!
                You can also view your review history to keep you on the streak
                of reviewing and becoming Articulate!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden w-full flex-row justify-between md:flex">
        <div className="flex w-[550px] flex-col gap-2">
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(0);
              }}
              type="radio"
              name="my-accordion-2"
              defaultChecked
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 0: Sign Up and Install PWA
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                Sign up for an account and install the PWA on your phone to
                start your language journey with Articulate! Once the app is on
                the homescreen, you can use it like any other app, even offline!
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(1);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 1: Search Word or Phrase
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                Search for a word or phrase that you want to learn more about,
                and Articulate will provide you with a simplified definition,
                along with the formal definition, and showcase three practical
                example sentences. Additionally, you'll discover five related
                hashtags that offer further context and understanding.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(2);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 2: Swipe and Save to a Lexicon
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                After receiving the word/phrase card, you can swipe to send the
                card to your Surface or Deep Lexicon. Send words you want to use
                in daily conversation to the 'Surface' Lexicon, and words you
                want to store for future reference to the 'Deep' Lexicon.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(3);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 3: View Saved Words in Lexicons
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                You can view your saved words in the 'Surface' and 'Deep'
                Lexicons and press on a word card to view its definition and
                examples.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(4);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 4: Review Words in Surface Lexicon
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                On the Review page, you can view all the words in your 'Surface'
                Lexicon and press on a word you want to review. Here, you will
                try to type a sentence with the word and you will be given
                Feedback based on how well you used the word in context.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(5);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 5: Earn Points for Reviewing!
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                After reviewing a word in the Surface Lexicon, you will earn
                points based on how well you used the word in context. You can
                earn new badges as you accumulate points!
              </p>
            </div>
          </div>

          <div className="collapse collapse-arrow bg-base-200">
            <input
              onClick={() => {
                setMockup(6);
              }}
              type="radio"
              name="my-accordion-2"
            />
            <div className="collapse-title text-lg font-bold lg:text-2xl">
              Step 6: Check your Progress!
            </div>
            <div className="collapse-content sm:text-sm lg:text-lg">
              <p>
                On the User Dashboard page, you can view your progress, see how
                many words you've learned, and how many badges you've collected!
                You can also view your review history to keep you on the streak
                of reviewing and becoming Articulate!
              </p>
            </div>
          </div>
        </div>
        <HowItWorksMockup mockupSelected={mockup} />
      </div>
    </>
  );
}

export default HowItWorksAccordion;
