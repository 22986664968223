import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import star from "../Lottie/star.json";
import good from "../Lottie/good.json";
import sad from "../Lottie/sad.json";

function FeedbackWIcon({ evaluation }) {
  const [lottie, setLottie] = useState(null);
  const [text, setText] = useState("");
  const [points, setPoints] = useState(0);

  useEffect(() => {
    switch (evaluation) {
      case "Good":
        setLottie(good);
        setText("Good!");
        setPoints(3);
        break;
      case "Perfect":
        setLottie(star);
        setText("Perfect!");
        setPoints(5);
        break;
      case "Bad":
        setLottie(sad);
        setText("Needs Improvement!");
        setPoints(0);
        break;
      default:
        setLottie(null);
        setText("");
        setPoints(0);
        break;
    }
  }, [evaluation]);

  return (
    <div className="mb-40 flex flex-col items-center justify-center">
      <div className="-ml-12 flex flex-row items-center justify-center">
        <Lottie
          className="-mr-10 h-44 w-44"
          animationData={lottie}
          loop={true}
        ></Lottie>
        <div className="text-3xl">{text}</div>
      </div>
      <div className="-mt-12 text-2xl">You earned {points} Exp!</div>
    </div>
  );
}
export default FeedbackWIcon;
