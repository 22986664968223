import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useGlobalData } from "../GlobalDataProvider";

const ReviewChart = ({ userId }) => {
  const { chartData, chartIsLoading } = useGlobalData();
  const [loading, setLoading] = useState(true);

  const renderChart = () => {
    return (
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={chartData}>
          <XAxis
            dataKey="date"
            interval={chartData.length > 7 ? 6 : 0}
            angle={-20}
            textAnchor="end"
          />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="glass mx-auto flex w-[90vw] flex-col justify-center rounded-3xl p-4">
      <div className="mb-2 flex items-center justify-between text-2xl">
        Recent Review History
      </div>
      <div className="-ml-8">
        {chartIsLoading ? (
          <div className="flex h-auto w-auto items-center justify-center">
            <div class="pyramid-loader">
              <div class="wrapper">
                <span class="side side1"></span>
                <span class="side side2"></span>
                <span class="side side3"></span>
                <span class="side side4"></span>
                <span class="shadow"></span>
              </div>
            </div>
          </div>
        ) : chartData.length > 0 ? (
          renderChart()
        ) : (
          <p className="py-8 text-center">
            No review data available yet. Start reviewing to see your progress!
          </p>
        )}
      </div>
    </div>
  );
};

export default ReviewChart;
