import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonButtons,
  IonButton,
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useGlobalData } from "../GlobalDataProvider";
import FeedbackWIcon from "./FeedbackWIcon";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function Review() {
  const [user] = useAuthState(auth);

  const {
    surfaceData,
    fetchSurfaceData,
    surfaceDataAlphabetical,
    updateSurfaceData,
  } = useGlobalData();
  const [selectedItem, setSelectedItem] = useState(null);
  const [input, setInput] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [submittedAlready, setSubmittedAlready] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [evaluation, setEvaluation] = useState("");
  const [reason, setReason] = useState("");
  const [betterSentence, setBetterSentence] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState("Date");
  const [points, setPoints] = useState(0);

  function formatOutputText(output) {
    const parts = output;
    if (parts.length === 0) return;

    let evaluationIndex = -1;
    let reasonIndex = -1;
    let betterSentenceIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].includes("Evaluation:")) {
        evaluationIndex = i + 1;
      }
      if (parts[i].includes("Reason:")) {
        reasonIndex = i + 1;
      }
      if (
        i < parts.length &&
        parts[i].includes("Better Sentence:") &&
        parts[i + 1].includes("N/A") === false
      ) {
        betterSentenceIndex = i + 1;
      }
    }

    if (evaluationIndex !== -1) {
      setEvaluation(parts[evaluationIndex]);
    }
    if (reasonIndex !== -1) {
      setReason(parts[reasonIndex]);
    }
    if (betterSentenceIndex !== -1) {
      setBetterSentence(parts[betterSentenceIndex]);
    }
    return output.join("\n");
  }
  const [realDefinition, setRealDefinition] = useState(
    "No definition available.",
  );
  const [outputFeedback, setOutputFeedback] = useState([]);

  function resetVariables() {
    setEvaluation("");
    setReason("");
    setBetterSentence("");
    setPoints(0);
    setOutputFeedback([]);
  }
  useEffect(() => {
    formatOutputText(outputFeedback);
  }, [outputFeedback]);
  const [botMsg, setBotMsg] = useState("A better sentence is:");
  useEffect(() => {
    if (evaluation === "" || reason === "") return;
    if (betterSentence === "") {
      setUserMessages([...userMessages, { role: "bot", message: reason }]);
    } else {
      setUserMessages([
        ...userMessages,
        // { role: "bot", message: evaluation },
        { role: "bot", message: reason },
        { role: "bot", message: botMsg },
        { role: "bot", message: betterSentence },
      ]);
    }
  }, [evaluation, reason, betterSentence]);

  function formatText(output) {
    const parts = output;

    if (parts.length === 0) return;

    let realDefinitionIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].includes("Definition:")) {
        realDefinitionIndex = i + 1;
      }
    }
    if (realDefinitionIndex !== -1) {
      setRealDefinition(parts[realDefinitionIndex]);
    }
  }
  const fetchResponseData = async () => {
    setLoading(true);
    resetVariables();
    try {
      const response = await axios.get(
        `${baseUrl}get_improved_response?word_or_phrase=${selectedItem["WordPhrase"]}&sentence=${input}&definition=${realDefinition}`,
      );
      setOutputFeedback(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //   const inputElement = useRef(null);
  //   useEffect(() => {
  //     if (inputElement && inputElement.current) {
  //       inputElement.current.onfocus = () => {
  //         document.body.scrollTop = 0;
  //       };
  //     }
  //   }, [inputElement]);

  const countWords = (sentence) => {
    return sentence.split(" ").filter(Boolean).length;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      if (submittedAlready) {
        event.target.blur();
      } else {
        if (countWords(input) >= 6) {
          const updatedUserMessages = [
            ...userMessages,
            { role: "user", message: input },
          ];
          setUserMessages(updatedUserMessages);
          fetchResponseData(updatedUserMessages);
          setInput("");
          setSubmittedAlready(true);
          event.target.blur();
        } else {
          alert("Please enter at least 6 words.");
        }
      }
    }
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    formatText(item["output"]);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setUserMessages([]);
    setInput("");
    setSubmittedAlready(false);
  };
  const [renderSurfaceData, setRenderSurfaceData] = useState([]);

  const calculatePoints = (evaluation) => {
    switch (evaluation) {
      case "Good":
        setPoints(3);
        break;
      case "Perfect":
        setPoints(5);
        break;
      case "Bad":
        setPoints(0);
        break;
      default:
        setPoints(0);
        break;
    }
  };
  useEffect(() => {
    const renderData =
      sortOption === "Date" ? surfaceData : surfaceDataAlphabetical;
    setRenderSurfaceData(renderData);
  }, [sortOption, surfaceData, updateSurfaceData]);

  useEffect(() => {
    calculatePoints(evaluation);
  }, [evaluation]);

  useEffect(() => {
    if (selectedItem === null) return;
    addPointsToItem(selectedItem["id"]);
  }, [points]);

  async function addPointsToItem(id) {
    try {
      const userRef = firestore.collection("users").doc(user.uid);
      const surfaceRef = userRef.collection("surface").doc(id);

      // Update points in the Firestore document
      await surfaceRef.update({
        points: firebase.firestore.FieldValue.increment(points),
      });

      updateSurfaceData();
      // console.log("Points updated successfully:", points);
    } catch (error) {
      console.error("Error updating points:", error);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-2xl lg:text-3xl">Review</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <select
          onChange={(e) => setSortOption(e.target.value)}
          className="select select-bordered my-2 ml-1 w-auto max-w-xs bg-primary/20"
        >
          <option selected>Date</option>
          <option>Alphabetical</option>
          {/* <option onClick={setSortOption("date")}>Greedo</option> */}
        </select>
        <IonList>
          {renderSurfaceData.map((item, index) => (
            <IonItem key={index} onClick={() => handleOpenModal(item)}>
              <IonLabel>{item["WordPhrase"]}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        <IonModal
          isOpen={selectedItem !== null}
          onDidDismiss={handleCloseModal}
        >
          {selectedItem && (
            <>
              <IonHeader>
                <IonToolbar>
                  <IonTitle mode="ios">{selectedItem["WordPhrase"]}</IonTitle>
                  <IonButtons slot="end">
                    <IonButton onClick={handleCloseModal}>Close</IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent color="light">
                <div className="flex h-full flex-col justify-between">
                  <div className="mt-3">
                    <div className="chat chat-start">
                      <div className="chat-bubble bg-primary/10 text-gray-900">
                        Try to form a sentence
                        <br /> with: {selectedItem["WordPhrase"]}
                      </div>
                    </div>
                    {userMessages.map((message, index) => (
                      <div
                        key={index}
                        className={`chat ${message.role === "user" ? "chat-end" : "chat-start"}`}
                      >
                        <div className="chat-bubble bg-primary/10 text-gray-900">
                          {message.message}
                        </div>
                      </div>
                    ))}
                  </div>
                  {!submittedAlready && (
                    <div className="mb-5 flex flex-row items-center justify-between px-5 md:mb-2 lg:mx-2 lg:px-0">
                      <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder={
                          submittedAlready
                            ? "Review Session Over"
                            : "Type your sentence here"
                        }
                        disabled={submittedAlready}
                        className={`input mr-4 w-10/12 border-primary/50 bg-transparent focus:shadow disabled:bg-gray-500 md:w-11/12`}
                      />
                      <button
                        onClick={(event) => {
                          if (countWords(input) >= 6) {
                            const updatedUserMessages = [
                              ...userMessages,
                              { role: "user", message: input },
                            ];
                            setUserMessages(updatedUserMessages);
                            fetchResponseData(updatedUserMessages);
                            setInput("");
                            setSubmittedAlready(true);
                            event.target.blur();
                          } else {
                            alert("Please enter at least 6 words.");
                          }
                        }}
                        disabled={submittedAlready || input === ""}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="lucide lucide-send-horizontal h-12 w-12 rounded-lg bg-primary/50 p-1"
                        >
                          <path d="m3 3 3 9-3 9 19-9Z" />
                          <path d="M6 12h16" />
                        </svg>
                      </button>
                    </div>
                  )}
                  {submittedAlready &&
                    (loading ? (
                      <div className="mb-28 flex h-full items-center justify-center">
                        <div className=" flex flex-col items-center">
                          <div className="typewriter ">
                            <div className="slide">
                              <i></i>
                            </div>
                            <div className="paper"></div>
                            <div className="keyboard"></div>
                          </div>
                          <div>Loading...</div>
                        </div>
                      </div>
                    ) : (
                      <FeedbackWIcon evaluation={evaluation} />
                    ))}
                </div>
              </IonContent>
            </>
          )}
        </IonModal>
        <div className="h-28 lg:h-16"></div> {/* Space for IonTabBar */}
      </IonContent>
    </IonPage>
  );
}

export default Review;
