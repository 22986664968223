import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import toast, { Toaster } from "react-hot-toast";
import { CircleHelp } from "lucide-react";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { closeOutline } from "ionicons/icons";

import { useGlobalData } from "../GlobalDataProvider";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function Deep() {
  const [user] = useAuthState(auth);
  // const [DeepData, setDeepData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [update, setUpdate] = useState(false);
  const {
    deepData,
    deepUpdate,
    updateDeepData,
    updateSurfaceData,
    deepDataAlphabetical,
  } = useGlobalData();
  const [simpDefinition, setSimpDefinition] = useState("");
  const [realDefinition, setRealDefinition] = useState("");
  const [exampleSentences, setExampleSentences] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [sortOption, setSortOption] = useState("Date");

  function formatText(item) {
    const parts = item["output"];

    if (parts.length === 0) return;

    let simpDefinitionIndex = -1;
    let realDefinitionIndex = -1;
    let sentencesIndex = -1;
    let relevantKeywordsIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (
        parts[i].includes("Simplified Definition:") ||
        parts[i].includes("Simplified Explanation")
      ) {
        simpDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Definition")) {
        realDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Sentences")) {
        sentencesIndex = i + 1;
      }
      if (
        parts[i].includes("Relevant Keywords") ||
        parts[i].includes("Hashtags")
      ) {
        relevantKeywordsIndex = i + 1;
      }
    }

    if (simpDefinitionIndex !== -1) {
      setSimpDefinition(parts[simpDefinitionIndex]);
    }

    if (realDefinitionIndex !== -1) {
      setRealDefinition(parts[realDefinitionIndex]);
    }

    if (sentencesIndex !== -1) {
      const sentences = [];
      for (let i = sentencesIndex; i < parts.length; i++) {
        if (
          parts[i].includes("Relevant Keywords") ||
          parts[i].includes("Hashtags")
        ) {
          break;
        }
        sentences.push(parts[i]);
      }
      setExampleSentences(sentences);
    }

    if (relevantKeywordsIndex !== -1) {
      const hashtagsArray = [];
      for (let i = relevantKeywordsIndex; i < parts.length; i++) {
        const part = parts[i].split(" ");
        hashtagsArray.push(...part);
      }
      setHashtags(hashtagsArray);
    }
  }

  function resetVariables() {
    setSimpDefinition("");
    setRealDefinition("");
    setExampleSentences([]);
    setHashtags([]);
  }

  const deleteDeepData = async (id) => {
    const slidingItems = document.querySelectorAll("ion-item-sliding");
    slidingItems.forEach((item) => item.close());
    const userRef = firestore.collection("users").doc(user.uid);
    const surfaceRef = userRef.collection("deep").doc(id);
    await surfaceRef.delete();
    updateDeepData();
  };

  const moveToSurface = async (item) => {
    toast.success("Sent to Surface Lexicon!");
    const userRef = firestore.collection("users").doc(user.uid);
    const surfaceRef = userRef.collection("surface").doc();
    await surfaceRef.set({
      WordPhrase: item["WordPhrase"],
      output: item["output"],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      points: item["points"],
    });
    deleteDeepData(item["id"]);
    updateSurfaceData();
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    formatText(item);
  };

  const handleCloseModal = () => {
    resetVariables();
    setSelectedItem(null);
  };

  const [renderDeepData, setRenderDeepData] = useState([]);

  useEffect(() => {
    const renderData = sortOption === "Date" ? deepData : deepDataAlphabetical;
    setRenderDeepData(renderData);
  }, [sortOption, deepData, updateDeepData]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-2xl lg:text-3xl">Deep Lexicon</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <div className="flex flex-row items-center justify-between">
          <select
            onChange={(e) => setSortOption(e.target.value)}
            className="select select-bordered my-2 ml-1 w-auto max-w-xs bg-primary/10"
          >
            <option selected>Date</option>
            <option>Alphabetical</option>
            {/* <option onClick={setSortOption("date")}>Greedo</option> */}
          </select>
          <div
            className="tooltip tooltip-left mr-6"
            data-tip="Tap on a word to view card. You can swipe left on a word to delete or right to move to Surface Lexicon!"
          >
            <CircleHelp width={30} />
          </div>
        </div>
        <IonList>
          {renderDeepData.map((item, index) => (
            <IonItemSliding key={index}>
              <IonItemOptions side="start">
                <IonItemOption
                  onClick={() => moveToSurface(item)}
                  color="primary"
                >
                  ➡️Surface
                </IonItemOption>
              </IonItemOptions>
              <IonItem onClick={() => handleOpenModal(item)}>
                <IonLabel>{item["WordPhrase"]}</IonLabel>
                {sortOption === "Date" && (
                  <p>{item.createdAt.toDate().toDateString()}</p>
                )}
              </IonItem>

              <IonItemOptions side="end">
                <IonItemOption
                  onClick={() => {
                    toast("Word Deleted!", {
                      icon: "🗑️",
                    });
                    deleteDeepData(item["id"]);
                  }}
                  color="danger"
                >
                  🗑️Delete
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
        </IonList>
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 2000,
            style: {
              border: "1px solid #713200",
              padding: "16px",
              marginBottom: "12vh",
              color: "#713200",
            },
          }}
        ></Toaster>
        <IonModal
          isOpen={selectedItem !== null}
          onDidDismiss={handleCloseModal}
        >
          {selectedItem && (
            <>
              <IonHeader>
                <IonToolbar>
                  <IonTitle mode="ios" className="text-3xl">
                    {/* {selectedItem["WordPhrase"]} */}
                  </IonTitle>
                  <IonButtons slot="end">
                    <IonButton onClick={handleCloseModal}>
                      <IonIcon
                        className="h-8 w-8"
                        icon={closeOutline}
                      ></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <div className="card mx-3 my-3 w-auto bg-primary/20">
                  <div className="card-body">
                    <h2 className="mb-1 mt-1 text-center text-3xl">
                      {selectedItem["WordPhrase"]}
                    </h2>
                    <ul className="-mt-6">
                      <li>
                        <h5>Simplified Definition:</h5> <p>{simpDefinition}</p>
                      </li>
                      <li>
                        <h5>Formal Definition:</h5> <p>{realDefinition}</p>
                      </li>
                      <li>
                        <h5>Sentences:</h5>
                        <ol>
                          {exampleSentences.map((sentence, index) => {
                            return (
                              <li className="ml-5 list-decimal" key={index}>
                                {sentence}
                              </li>
                            );
                          })}
                        </ol>
                      </li>
                      <li>
                        <h5>Relevant Keywords:</h5>
                      </li>
                    </ul>
                    <div className="card-actions justify-center">
                      {hashtags.map((hashtag, index) => (
                        <div key={index} class="badge badge-outline">
                          {hashtag}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </IonContent>
            </>
          )}
        </IonModal>
        <div className="h-28 lg:h-16"></div> {/* Space for IonTabBar */}
      </IonContent>
    </IonPage>
  );
}

export default Deep;
