import { IonButton, IonToast } from "@ionic/react";
import React from "react";

function LexButton({ id, children, onClick }) {
  return (
    <>
    <IonButton id={id} size="large" className="custom-button" onClick={onClick}>{children}</IonButton>
    <IonToast
        trigger={id}
        message={"Added to " + children + " Lexicon!"}
        duration={3000}
        position={"bottom"}
        positionAnchor={"ion-tab-bar"}
        buttons={[
          {
            text: 'Dismiss',
            role: 'cancel',
          },
        ]}
      ></IonToast>
      </>
  );
}

export default LexButton;