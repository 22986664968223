import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import bronze from "../assets/bronze.png";
import gold from "../assets/gold.png";
import silver from "../assets/silver.png";
import trophy from "../assets/trophy.png";
import { CircleDashed, CircleHelp } from "lucide-react";
import FeedbackWIcon from "../Review/FeedbackWIcon";
import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { closeOutline } from "ionicons/icons";

import { useGlobalData } from "../GlobalDataProvider";
import { render } from "@testing-library/react";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function Surface() {
  const [user] = useAuthState(auth);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [surfaceData, setSurfaceData] = useState([]);
  // const [update, setUpdate] = useState(false);
  const [simpDefinition, setSimpDefinition] = useState("");
  const [realDefinition, setRealDefinition] = useState("");
  const [exampleSentences, setExampleSentences] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [sortOption, setSortOption] = useState("Date");

  // review variables
  const [input, setInput] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [submittedAlready, setSubmittedAlready] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [evaluation, setEvaluation] = useState("");
  const [reason, setReason] = useState("");
  const [betterSentence, setBetterSentence] = useState("");
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(-1);

  const inputRef = useRef(null);

  const {
    surfaceData,
    surfaceUpdate,
    updateSurfaceData,
    totalSurface,
    setTotalSurface,
    updateDeepData,
    surfaceDataAlphabetical,
    fetchSurfaceData,
    surfaceDataOrderByPoints,
    fetchReviewData,
  } = useGlobalData();

  function formatOutputEvaluation(output) {
    const parts = output;
    if (parts.length === 0) return;

    let evaluationIndex = -1;
    let reasonIndex = -1;
    let betterSentenceIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].includes("Evaluation:")) {
        evaluationIndex = i + 1;
      }
      if (parts[i].includes("Reason:")) {
        reasonIndex = i + 1;
      }
      if (
        i < parts.length &&
        parts[i].includes("Better Sentence:") &&
        parts[i + 1].includes("N/A") === false
      ) {
        betterSentenceIndex = i + 1;
      }
    }

    if (evaluationIndex !== -1) {
      setEvaluation(parts[evaluationIndex]);
    }
    if (reasonIndex !== -1) {
      setReason(parts[reasonIndex]);
    }
    if (betterSentenceIndex !== -1) {
      setBetterSentence(parts[betterSentenceIndex]);
    }
    return output.join("\n");
  }

  function formatText(item) {
    const parts = item["output"];

    if (parts.length === 0) return;

    let simpDefinitionIndex = -1;
    let realDefinitionIndex = -1;
    let sentencesIndex = -1;
    let relevantKeywordsIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (
        parts[i].includes("Simplified Definition:") ||
        parts[i].includes("Simplified Explanation")
      ) {
        simpDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Definition")) {
        realDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Sentences")) {
        sentencesIndex = i + 1;
      }
      if (
        parts[i].includes("Relevant Keywords") ||
        parts[i].includes("Hashtags")
      ) {
        relevantKeywordsIndex = i + 1;
      }
    }

    if (simpDefinitionIndex !== -1) {
      setSimpDefinition(parts[simpDefinitionIndex]);
    }

    if (realDefinitionIndex !== -1) {
      setRealDefinition(parts[realDefinitionIndex]);
    }

    if (sentencesIndex !== -1) {
      const sentences = [];
      for (let i = sentencesIndex; i < parts.length; i++) {
        if (
          parts[i].includes("Relevant Keywords") ||
          parts[i].includes("Hashtags")
        ) {
          break;
        }
        sentences.push(parts[i]);
      }
      setExampleSentences(sentences);
    }

    if (relevantKeywordsIndex !== -1) {
      const hashtagsArray = [];
      for (let i = relevantKeywordsIndex; i < parts.length; i++) {
        const part = parts[i].split(" ");
        hashtagsArray.push(...part);
      }
      setHashtags(hashtagsArray);
    }
  }
  const [outputFeedback, setOutputFeedback] = useState([]);
  function resetReviewVariables() {
    setEvaluation("");
    setReason("");
    setBetterSentence("");
    setPoints(-1);
    setOutputFeedback([]);
  }

  function resetVariables() {
    setInput("");
    setUserMessages([]);
    setSubmittedAlready(false);
    setResponseData([]);
    setLoading(false);
  }

  const moveToDeep = async (item) => {
    toast.success("Sent to Deep Lexicon!");
    const userRef = firestore.collection("users").doc(user.uid);
    const deepRef = userRef.collection("deep").doc();
    await deepRef.set({
      WordPhrase: item["WordPhrase"],
      output: item["output"],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      points: item["points"],
    });
    deleteSurfaceData(item["id"]);
    updateDeepData();
  };
  useEffect(() => {
    formatOutputEvaluation(outputFeedback);
  }, [outputFeedback]);
  const [botMsg, setBotMsg] = useState("A better sentence is:");
  useEffect(() => {
    if (evaluation === "" || reason === "") return;
    if (betterSentence === "") {
      setUserMessages([...userMessages, { role: "bot", message: reason }]);
    } else {
      setUserMessages([
        ...userMessages,
        // { role: "bot", message: evaluation },
        { role: "bot", message: reason },
        { role: "bot", message: botMsg },
        { role: "bot", message: betterSentence },
      ]);
    }
  }, [evaluation, reason, betterSentence]);
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    formatText(item);
  };

  const handleCloseModal = () => {
    resetVariables();
    resetReviewVariables();
    setSelectedItem(null);
  };

  const deleteSurfaceData = async (id) => {
    const slidingItems = document.querySelectorAll("ion-item-sliding");
    slidingItems.forEach((item) => item.close());
    const userRef = firestore.collection("users").doc(user.uid);
    const surfaceRef = userRef.collection("surface").doc(id);
    await surfaceRef.delete();
    updateSurfaceData();
  };

  async function canDismiss(data, role) {
    return role !== "gesture";
  }
  const [renderSurfaceData, setRenderSurfaceData] = useState([]);

  useEffect(() => {
    if (sortOption === "Date") {
      setRenderSurfaceData(surfaceData);
    } else if (sortOption === "Alphabetical") {
      setRenderSurfaceData(surfaceDataAlphabetical);
    } else if (sortOption === "Points") {
      setRenderSurfaceData(surfaceDataOrderByPoints);
    }
  }, [sortOption, surfaceData, updateSurfaceData]);

  function calculateAndDisplayMedal(points) {
    if (points < 10) {
      return <CircleDashed width={40} height={35} />;
    } else if (points < 30) {
      return <img className="w-10" src={bronze}></img>;
    } else if (points < 50) {
      return <img className="w-10" src={silver}></img>;
    } else if (points < 70) {
      return <img className="w-10" src={gold}></img>;
    } else {
      return <img className="w-10" src={trophy}></img>;
    }
  }

  // review page functions
  const countWords = (sentence) => {
    return sentence.split(" ").filter(Boolean).length;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      if (submittedAlready) {
        event.target.blur();
      } else {
        if (countWords(input) >= 6) {
          const updatedUserMessages = [
            ...userMessages,
            { role: "user", message: input },
          ];
          setUserMessages(updatedUserMessages);
          fetchResponseData(updatedUserMessages);
          setInput("");
          setSubmittedAlready(true);
          event.target.blur();
        } else {
          alert("Please enter at least 6 words.");
        }
      }
    }
  };

  const fetchResponseData = async () => {
    setLoading(true);
    resetReviewVariables();
    try {
      const response = await axios.get(
        `${baseUrl}get_improved_response?word_or_phrase=${selectedItem["WordPhrase"]}&sentence=${input}&definition=${realDefinition}`,
      );
      setOutputFeedback(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    calculatePoints(evaluation);
  }, [evaluation]);

  useEffect(() => {
    if (selectedItem === null) return;
    if (points === -1) return;
    addPointsToItem(selectedItem["id"]);
  }, [points]);
  const autoResize = (ref) => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  };
  useEffect(() => {
    autoResize(inputRef);
  }, [input]);

  const calculatePoints = (evaluation) => {
    switch (evaluation) {
      case "Good":
        setPoints(3);
        break;
      case "Perfect":
        setPoints(5);
        break;
      case "Bad":
        setPoints(0);
        break;
      default:
        setPoints(0);
        break;
    }
  };
  async function addPointsToItem(id) {
    try {
      const userRef = firestore.collection("users").doc(user.uid);
      const surfaceRef = userRef.collection("surface").doc(id);
      const today = new Date().toISOString().split("T")[0];

      const reviewRef = userRef.collection("review").doc(today);

      // Update points in the Firestore document
      await surfaceRef.update({
        points: firebase.firestore.FieldValue.increment(points),
      });
      await reviewRef.set(
        {
          reviewCount: firebase.firestore.FieldValue.increment(1),
        },
        { merge: true },
      );

      updateSurfaceData();
      fetchReviewData();
      // console.log("Points updated successfully:", points);
    } catch (error) {
      console.error("Error updating points:", error);
    }
  }

  const [selectedTab, setSelectedTab] = useState("tab1");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-2xl lg:text-3xl">Surface Lexicon</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <div className="flex flex-row items-center justify-between">
          <select
            onChange={(e) => setSortOption(e.target.value)}
            className="select select-bordered my-2 ml-1 w-auto max-w-xs bg-primary/10"
          >
            <option selected>Date</option>
            <option>Alphabetical</option>
            <option>Points</option>
          </select>
          <div
            className="tooltip tooltip-left mr-6"
            data-tip="Tap on a word to view card and review section. Swipe left on a word to delete or right to move to Deep Lexicon!"
          >
            <CircleHelp width={30} />
          </div>
        </div>
        {/* <IonButtons>
          <IonButton onClick={() => setSortOption("Date")}>Date</IonButton>
          <IonButton onClick={() => setSortOption("Alphabetical")}>
            Alphabetical
          </IonButton>
          <IonButton onClick={() => setSortOption("Points")}>Points</IonButton>
        </IonButtons> */}
        <IonList>
          {renderSurfaceData.map((item, index) => (
            <IonItemSliding key={index}>
              <IonItemOptions side="start">
                <IonItemOption onClick={() => moveToDeep(item)} color="primary">
                  ➡️Deep
                </IonItemOption>
              </IonItemOptions>
              <IonItem onClick={() => handleOpenModal(item)}>
                <IonLabel>{item["WordPhrase"]}</IonLabel>
                {/* <p>{item.createdAt.toDate().toDateString()}</p>
                 */}
                <div className="flex flex-row items-center gap-5">
                  <IonLabel>
                    Exp: {item["points"] ? item["points"] : 0}
                  </IonLabel>
                  {calculateAndDisplayMedal(
                    item["points"] ? item["points"] : 0,
                  )}
                </div>
              </IonItem>
              <IonItemOptions>
                <IonItemOption
                  onClick={() => {
                    toast("Word Deleted!", {
                      icon: "🗑️",
                    });
                    deleteSurfaceData(item["id"]);
                  }}
                  color="danger"
                >
                  🗑️Delete
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
        </IonList>
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 2000,
            style: {
              border: "1px solid #713200",
              padding: "16px",
              marginBottom: "12vh",
              color: "#713200",
            },
          }}
        ></Toaster>
        <IonModal
          isOpen={selectedItem !== null}
          onDidDismiss={handleCloseModal}
          canDismiss={canDismiss}
        >
          {selectedItem && (
            <>
              <IonHeader>
                <IonToolbar>
                  <IonTitle mode="ios" className="text-3xl">
                    {/* {selectedItem["WordPhrase"]} */}
                  </IonTitle>
                  <IonButtons slot="end">
                    <IonButton onClick={handleCloseModal}>
                      <IonIcon
                        icon={closeOutline}
                        className="h-8 w-8"
                      ></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <div
                  role="tablist"
                  className="flex h-full flex-col items-center justify-start"
                >
                  <div className="tabs tabs-bordered flex h-14 w-full justify-center">
                    <input
                      type="radio"
                      name="my_tabs_1"
                      role="tab"
                      className="tab h-full flex-auto text-xl text-black"
                      aria-label="Word Info"
                      checked={selectedTab === "tab1"}
                      onChange={() => setSelectedTab("tab1")}
                    />
                    <input
                      type="radio"
                      name="my_tabs_1"
                      role="tab"
                      className="tab h-full flex-auto text-xl text-black"
                      aria-label="Review"
                      checked={selectedTab === "tab2"}
                      onChange={() => setSelectedTab("tab2")}
                    />
                  </div>
                  <div className="flex h-full w-full flex-col justify-between">
                    <div
                      role="tabpanel"
                      className={`tab-content ${selectedTab === "tab1" ? "block" : "hidden"}`}
                    >
                      <div className="card mx-3 my-3 w-auto bg-primary/20 shadow-xl">
                        <div className="card-body">
                          <h2 className="mb-1 mt-1 text-center text-3xl">
                            {selectedItem["WordPhrase"]}
                          </h2>
                          <ul className="-mt-6">
                            <li>
                              <h5>Simplified Definition:</h5>{" "}
                              <p>{simpDefinition}</p>
                            </li>
                            <li>
                              <h5>Formal Definition:</h5>{" "}
                              <p>{realDefinition}</p>
                            </li>
                            <li>
                              <h5>Sentences:</h5>
                              <ol>
                                {exampleSentences.map((sentence, index) => {
                                  return (
                                    <li
                                      className="ml-5 list-decimal"
                                      key={index}
                                    >
                                      {sentence}
                                    </li>
                                  );
                                })}
                              </ol>
                            </li>
                            <li>
                              <h5>Relevant Keywords:</h5>
                            </li>
                          </ul>
                          <div className="card-actions justify-center">
                            {hashtags.map((hashtag, index) => (
                              <div key={index} class="badge badge-outline">
                                {hashtag}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      role="tabpanel"
                      className={`tab-content ${selectedTab === "tab2" ? "block" : "hidden"}`}
                    >
                      <div className="flex h-full w-auto flex-col gap-3">
                        <div className="mt-3">
                          <div className="chat chat-start">
                            <div className="chat-bubble bg-primary/10 text-gray-900">
                              Try to form a sentence
                              <br /> with: {selectedItem["WordPhrase"]}
                            </div>
                          </div>
                          {userMessages.map((message, index) => (
                            <div
                              key={index}
                              className={`chat ${message.role === "user" ? "chat-end" : "chat-start"}`}
                            >
                              <div className="chat-bubble bg-primary/10 text-gray-900">
                                {message.message}
                              </div>
                            </div>
                          ))}
                        </div>
                        {!submittedAlready && (
                          <div className="mb-5 flex flex-row items-center justify-between px-5 md:mb-2 lg:mx-2 lg:px-0">
                            <textarea
                              ref={inputRef}
                              type="text"
                              value={input}
                              onChange={(e) => setInput(e.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder={
                                submittedAlready
                                  ? "Review Session Over"
                                  : "Type your sentence here"
                              }
                              disabled={submittedAlready}
                              className={`textarea mr-4 w-10/12 border-primary/50 bg-transparent p-1 text-xl focus:shadow disabled:bg-gray-500 md:w-11/12`}
                            />
                            <button
                              onClick={(event) => {
                                if (countWords(input) >= 6) {
                                  const updatedUserMessages = [
                                    ...userMessages,
                                    { role: "user", message: input },
                                  ];
                                  setUserMessages(updatedUserMessages);
                                  fetchResponseData(updatedUserMessages);
                                  setInput("");
                                  setSubmittedAlready(true);
                                  event.target.blur();
                                } else {
                                  alert("Please enter at least 6 words.");
                                }
                              }}
                              disabled={submittedAlready || input === ""}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-send-horizontal h-12 w-12 rounded-lg bg-primary/50 p-1"
                              >
                                <path d="m3 3 3 9-3 9 19-9Z" />
                                <path d="M6 12h16" />
                              </svg>
                            </button>
                          </div>
                        )}
                        {submittedAlready &&
                          (loading ? (
                            <div className="mb-28 flex h-full items-center justify-center">
                              <div className=" flex flex-col items-center">
                                <div className="typewriter ">
                                  <div className="slide">
                                    <i></i>
                                  </div>
                                  <div className="paper"></div>
                                  <div className="keyboard"></div>
                                </div>
                                <div>Loading...</div>
                              </div>
                            </div>
                          ) : (
                            <FeedbackWIcon evaluation={evaluation} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </IonContent>
            </>
          )}
        </IonModal>
        <div className="h-28 lg:h-16"></div> {/* Space for IonTabBar */}
      </IonContent>
    </IonPage>
  );
}

export default Surface;
