import React from "react";
import Hero from "./HeroPage/hero";
import Why from "./HeroPage/why";
import HowItWorks from "./HeroPage/howitworks";
import Navbar from "./HeroPage/navbar";
import Footer from "./HeroPage/Footer";
import Roadmap from "./HeroPage/roadMap";
import Feedback from "./HeroPage/Feedback";
import InstallPWA from "./pwaInstall";
import ProductHunt from "./ProductHunt";

function HeroPage() {
  return (
    <>
      <main className="h-screen overflow-y-auto overflow-x-hidden scroll-smooth">
        <Navbar />
        <Hero />
        <Why />
        <HowItWorks />
        <Roadmap />
        <ProductHunt />
        <Feedback />
        <Footer />
      </main>
    </>
  );
}

export default HeroPage;
