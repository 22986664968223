import { roadmap1, roadmap2, roadmap3, roadmap4, roadmap5 } from "../assets";

export const roadmap = [
  {
    id: "0",
    title: "Speech recognition",
    text: "Enable voice to text input from users to make inputting text easier and more convenient.",
    date: "May 2024-Present",
    status: "progress",
    imageUrl: roadmap1,
  },
  {
    id: "1",
    title: "Gamification",
    text: "Introduce gamification elements so users can feel progression as they learn new words!",
    date: "May 2024-June 2024",
    status: "done",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Offline Functionality",
    text: "Implement offline functionality to allow users to access their lexicons even when they are not connected to the internet.",
    date: "May 2024-June 2024",
    status: "done",
    imageUrl: roadmap3,
  },
  {
    id: "3",
    title: "UX/UI Improvements",
    text: "Enhance the app's user interface and user experience to make it more intuitive and visually appealing.",
    date: "May 2024-Present",
    status: "progress",
    imageUrl: roadmap4,
  },

  {
    id: "4",
    title: "Explore Page",
    text: "Create a page where users can explore new words and add them to their lexicons.",
    date: "June 2024-Present",
    status: "progress",
    imageUrl: roadmap5,
  },
];
