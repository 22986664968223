import React, { useState } from "react";
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonApp,
  IonFab,
  IonFabButton,
  IonContent,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  library,
  libraryOutline,
  search,
  statsChartOutline,
  compassOutline,
} from "ionicons/icons";
import { setupIonicReact } from "@ionic/react";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import Search from "./Search";
import Surface from "./Surface";
import Deep from "./Deep";
import UserDashBoard from "../DashBoard/userDashBoard";
import Review from "../Review/Review";
import Explore from "./Explore";
import { useGlobalData } from "../GlobalDataProvider";

setupIonicReact();
function HomePage() {
  const { readCount } = useGlobalData();
  return (
    <IonApp>
      <IonReactRouter>
        <IonContent>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/dashboard">
                {<UserDashBoard />}
              </Route>
              <Route exact path="/search">
                {<Search />}
              </Route>
              <Route exact path="/surface">
                {<Surface />}
              </Route>
              <Route exact path="/deep">
                {<Deep />}
              </Route>
              <Route exact path="/articulator">
                {<Explore />}
              </Route>

              <Redirect exact from="/" to="/search" />
            </IonRouterOutlet>
            <IonTabBar
              mode="ios"
              slot="bottom"
              id="ion-tab-bar"
              className="flex h-16 w-11/12 flex-row bg-primary/20 shadow-lg backdrop-blur-lg lg:rounded-lg"
            >
              <IonTabButton
                className="bg-transparent"
                tab="dashboard"
                href="/dashboard"
              >
                <IonIcon className="h-8 w-8" icon={statsChartOutline} />
                <IonLabel className="text-[12px]">Dashboard</IonLabel>
              </IonTabButton>

              <IonTabButton
                className="bg-transparent"
                tab="surface"
                href="/surface"
              >
                <IonIcon className="h-8 w-8" icon={libraryOutline} />
                <IonLabel className="text-[12px]">Surface</IonLabel>
              </IonTabButton>

              <IonTabButton className="bg-transparent"></IonTabButton>

              <IonTabButton className="bg-transparent" tab="deep" href="/deep">
                <IonIcon className="h-8 w-8" icon={library} />
                <IonLabel className="text-[12px]">Deep</IonLabel>
              </IonTabButton>

              <IonTabButton
                className="bg-transparent"
                tab="articulator"
                href="/articulator"
              >
                <IonIcon className="h-8 w-8" icon={compassOutline} />
                <IonLabel className="text-[12px]">Articulator</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonContent>
        <IonFab
          className="bottom-14 bg-transparent sm:bottom-5"
          vertical="bottom"
          horizontal="center"
          slot="fixed"
        >
          <IonFabButton
            routerLink="/search"
            routerDirection="root"
            className="top-10 h-16 w-16 "
          >
            <IonIcon icon={search}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonReactRouter>
    </IonApp>
  );
}

export default HomePage;
