import React, { useState } from "react";
import SignIn from "../auth/SignIn";
import CreateNewAccountModal from "../auth/CreateNewAcc";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import InstallPWA from "../pwaInstall";

const auth = firebase.auth();

function Navbar() {
  const [user] = useAuthState(auth);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isCreateNewAccountOpen, setIsCreateNewAccountOpen] = useState(false);

  return (
    <div className="bg-blur navbar sticky top-0 z-50 h-12 rounded-lg bg-primary bg-opacity-20 shadow-lg backdrop-blur-lg">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
          >
            <li>
              <a
                href="#why-div"
                className="text-md font-bold text-base-content hover:underline"
              >
                Why Articulate?
              </a>
            </li>
            <li>
              <a
                href="#how-it-works-div"
                className="text-md font-bold text-base-content hover:underline"
              >
                How It Works
              </a>
            </li>
            <li>
              <a
                href="#roadmap-div"
                className="text-md font-bold text-base-content hover:underline"
              >
                Roadmap
              </a>
            </li>
          </ul>
        </div>
        <a
          href="#hero-div"
          className="btn btn-ghost text-lg text-base-content lg:text-xl"
        >
          <img
            className="hidden aspect-square h-8 w-8 rounded-lg bg-purple-200 lg:flex"
            src={require("../assets/logoArticulate.png")}
            alt="Logo"
          />
          Articulate
        </a>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <a
              href="#why-div"
              className="text-lg font-bold text-base-content hover:underline"
            >
              Why Articulate?
            </a>
          </li>
        </ul>
        <ul className="menu menu-horizontal px-1">
          <li>
            <a
              href="#how-it-works-div"
              className="text-lg font-bold text-base-content hover:underline"
            >
              How It Works
            </a>
          </li>
          <li>
            <a
              href="#roadmap-div"
              className="text-lg font-bold text-base-content hover:underline"
            >
              Roadmap
            </a>
          </li>
        </ul>
      </div>
      <div className="navbar-end flex flex-row gap-3">
        {user ? (
          <button
            className="text-md btn rounded-md border border-primary-content px-4 py-2 font-semibold"
            onClick={() => auth.signOut()}
          >
            Sign Out
          </button>
        ) : (
          <>
            <div className="hidden md:flex">
              <InstallPWA />
            </div>
            <button
              className="text-md btn rounded-md border border-primary-content px-4 py-2 font-semibold"
              onClick={() => setIsSignInOpen(true)}
            >
              Sign In
            </button>
            <button
              className="text-md btn rounded-md border border-primary-content bg-primary px-4 py-2 font-semibold text-blue-950 hover:text-white/80"
              onClick={() => setIsCreateNewAccountOpen(true)}
            >
              Sign Up
            </button>
          </>
        )}
      </div>
      <SignIn isOpen={isSignInOpen} onClose={() => setIsSignInOpen(false)} />
      <CreateNewAccountModal
        isOpen={isCreateNewAccountOpen}
        onClose={() => setIsCreateNewAccountOpen(false)}
      />
    </div>
  );
}

export default Navbar;
