import React from "react";
import mockup0 from "../assets/installPWAmockup.jpeg";
import mockup1 from "../assets/mockup1.PNG";
import mockup2 from "../assets/search.PNG";
import mockup3 from "../assets/surface.PNG";
import mockup4 from "../assets/review.PNG";
import mockup5 from "../assets/points.PNG";
import mockup6 from "../assets/dashboard.PNG";
import { BackgroundGradient } from "../ui/background-gradient";

export const mockupList = [
  {
    id: 0,
    imageUrl: mockup0,
    alt: "mockup0",
  },
  {
    id: 1,
    imageUrl: mockup1,
    alt: "mockup1",
  },
  {
    id: 2,
    imageUrl: mockup2,
    alt: "mockup2",
  },
  {
    id: 3,
    imageUrl: mockup3,
    alt: "mockup3",
  },
  {
    id: 4,
    imageUrl: mockup4,
    alt: "mockup4",
  },
  {
    id: 5,
    imageUrl: mockup5,
    alt: "mockup5",
  },
  {
    id: 6,
    imageUrl: mockup6,
    alt: "mockup6",
  },
];

function HowItWorksMockup({ mockupSelected }) {
  return (
    <div className="flex flex-row items-center justify-center">
      <BackgroundGradient className="-m-0.5 -mb-2 rounded-[50px] dark:bg-zinc-900">
        <div className="mockup-phone">
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1 relative">
              {mockupList.map((mockup) => {
                if (mockup.id === mockupSelected)
                  return (
                    <img
                      className="aspect-[1/2.15] w-full"
                      src={mockup.imageUrl}
                      alt={mockup.alt}
                    ></img>
                  );
              })}
            </div>
          </div>
        </div>
      </BackgroundGradient>
    </div>
  );
}

export default HowItWorksMockup;
