import React from "react";
import SignIn from "../auth/SignIn";
import { FlipWords } from "../ui/flip-words";
import { Button } from "../ui/outline-button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import InstallPWA from "../pwaInstall";

function Hero() {
  const words = [
    "Enhance",
    "Empower",
    "Advance",
    "Transform",
    "Propel",
    "Accelerate",
  ];
  return (
    <>
      <div className="relative">
        <div id="hero-div" className="absolute -top-20 left-0"></div>
      </div>
      <section id="hero" className="bg-base-neutral hero min-h-screen">
        <div className="hero-content items-center text-center">
          <div className="relative w-full max-w-lg">
            <div className="absolute -left-20 top-0 h-96 w-96 animate-blob rounded-full bg-primary opacity-20 mix-blend-multiply blur-xl filter"></div>
            <div className="animation-delay-2000 absolute top-0 h-96 w-96 animate-blob rounded-full bg-blue-300 opacity-20 mix-blend-multiply blur-xl filter"></div>
            <div className="animation-delay-4000 absolute -bottom-8 left-20 h-96 w-96 animate-blob rounded-full bg-pink-300 opacity-20 mix-blend-multiply blur-xl filter"></div>
            <div className="relative z-10 max-w-md">
              <h1 className="flex flex-col text-5xl">
                Welcome to
                <span className="title-gradient text-6xl font-bold">
                  ARTICULATE
                </span>
              </h1>
              <div className="flex items-center justify-center">
                <div className="font-regular mx-auto text-xl">
                  <FlipWords
                    words={words}
                    className={"font-bold text-primary"}
                  />
                  <span>Your Language Journey!</span>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <Link to="/guest">
                  <Button
                    borderRadius="1.75rem"
                    className=" border-black bg-black text-xl font-bold text-white dark:border-slate-800 dark:bg-slate-900 dark:text-white"
                  >
                    Try a Search!
                  </Button>
                </Link>
                <InstallPWA className="sm:hidden" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
