import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import toast, { Toaster } from "react-hot-toast";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function SignInModal({ isOpen, onClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSignInClose = () => {
    onClose();
    setEmail("");
    setPassword("");
  };
  const [loading, setLoading] = useState(false);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    auth
      .signInWithPopup(provider)
      .then((result) => {
        const uid = result.user.uid;
        const email = result.user.email;
        const name = result.user.displayName;

        const userRef = firestore.collection("users");
        userRef
          .doc(uid)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              userRef.doc(uid).set({ email, name });
            }
          });
      })
      .catch((error) => console.log(error.message))
      .finally(setLoading(true));
  };
  const signInWithEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => console.log(error.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (loading) {
      toast.loading("Signing in...");
    } else {
      toast.dismiss();
    }
  }, [loading]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      {loading && (
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 500,
            style: {
              borderRadius: "10px",
              background: "#333",
              marginTop: "10vh",
              color: "#fff",
            },
          }}
        ></Toaster>
      )}

      <dialog className="modal modal-open">
        <div className="modal-box">
          <h2 className="text-center text-xl font-semibold">
            Sign In with Google or Email!
          </h2>
          <button onClick={signInWithGoogle} className="btn mb-2 w-full">
            <svg
              width="16"
              height="16"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.0014 16.3109C30.0014 15.1598 29.9061 14.3198 29.6998 13.4487H16.2871V18.6442H24.1601C24.0014 19.9354 23.1442 21.8798 21.2394 23.1864L21.2127 23.3604L25.4536 26.58L25.7474 26.6087C28.4458 24.1665 30.0014 20.5731 30.0014 16.3109Z"
                fill="#4285F4"
              />
              <path
                d="M16.2862 30C20.1433 30 23.3814 28.7555 25.7465 26.6089L21.2386 23.1865C20.0322 24.011 18.4132 24.5866 16.2862 24.5866C12.5085 24.5866 9.30219 22.1444 8.15923 18.7688L7.9917 18.7827L3.58202 22.1272L3.52435 22.2843C5.87353 26.8577 10.6989 30 16.2862 30Z"
                fill="#34A853"
              />
              <path
                d="M8.16007 18.7688C7.85848 17.8977 7.68395 16.9643 7.68395 15.9999C7.68395 15.0354 7.85849 14.1021 8.1442 13.231L8.13621 13.0455L3.67126 9.64734L3.52518 9.71544C2.55696 11.6132 2.0014 13.7444 2.0014 15.9999C2.0014 18.2555 2.55696 20.3865 3.52518 22.2843L8.16007 18.7688Z"
                fill="#FBBC05"
              />
              <path
                d="M16.2863 7.4133C18.9688 7.4133 20.7783 8.54885 21.8101 9.4978L25.8418 5.64C23.3657 3.38445 20.1434 2 16.2863 2C10.699 2 5.87354 5.1422 3.52435 9.71549L8.14339 13.2311C9.30223 9.85555 12.5086 7.4133 16.2863 7.4133Z"
                fill="#EB4335"
              />
            </svg>
            Sign In with Google
          </button>
          <div className="divider divider-primary opacity-50">
            Or Sign In with Email/Password
          </div>

          <form method="dialog" className="flex flex-col gap-2">
            <label className="input input-bordered flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4 opacity-70"
              >
                <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
              </svg>
              <input
                type="email"
                placeholder="Email"
                className="w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <label className="input input-bordered flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                type="password"
                className="w-full"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>

            <button
              className="text-md btn btn-primary rounded-md border border-primary px-4 py-2 font-semibold"
              onClick={signInWithEmail}
            >
              Sign In
            </button>
            <button
              onClick={onSignInClose}
              className="btn btn-circle btn-ghost btn-md absolute right-2 top-2"
            >
              ✕
            </button>
          </form>
        </div>
      </dialog>
    </>,
    document.getElementById("modal-root"),
  );
}

export default SignInModal;
