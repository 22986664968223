"use client";
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "../../utils/cn";

export const FlipWords = ({ words, duration = 5000, className }) => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [totalIndex, setTotalIndex] = useState(0);
  let interval;
  const startAnimation = () => {
    let i = 0;
    setTotalIndex(totalIndex + 1);
    interval = setInterval(() => {
      i++;
      if (i === words.length) {
        i = 0;
      }
      const word = words[i];
      setCurrentWord(word);
    }, duration);
  };
  useEffect(() => {
    startAnimation();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AnimatePresence mode="sync">
      <motion.div
        initial={{
          opacity: 0,
          y: 10,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.4,
          ease: "easeInOut",
          type: "spring",
          stiffness: 100,
          damping: 10,
        }}
        exit={{
          opacity: 0,
          y: -40,
          x: 40,
          filter: "blur(8px)",
          scale: 2,
          position: "absolute",
        }}
        className={cn("relative z-10 inline-block px-2 text-left", className)}
      >
        {currentWord.split("").map((letter, index) => (
          <motion.span
            key={`${currentWord + totalIndex + index}`}
            initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            transition={{
              delay: index * 0.08,
              duration: 0.4,
            }}
            className="inline-block"
            exit={{ opacity: 0, y: -10, filter: "blur(8px)" }}
          >
            {letter}
          </motion.span>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};
