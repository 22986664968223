import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TinderCard from "react-tinder-card";
import toast, { Toaster } from "react-hot-toast";

import "../../App.css";
import {
  IonContent,
  IonHeader,
  IonButton,
  IonButtons,
  IonPage,
  IonTitle,
  IonToolbar,
  IonToast,
} from "@ionic/react";
import LexButton from "./LexButton";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import SignOut from "../auth/SignOut";

import { useGlobalData } from "../GlobalDataProvider";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function Search() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [starting, setStarting] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { surfaceUpdate, updateSurfaceData, deepUpdate, updateDeepData } =
    useGlobalData();

  const [simpDefinition, setSimpDefinition] = useState("");
  const [realDefinition, setRealDefinition] = useState("");
  const [exampleSentences, setExampleSentences] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [lastDirection, setLastDirection] = useState("");

  // const notify = () => {
  //   toast.success("Added to " + lastDirection + " Lexicon!");
  //   console.log(lastDirection);
  // };

  function formatText() {
    const parts = output.map((sentence) =>
      sentence.replace(/\*|\n/g, "").trim(),
    );

    if (parts.length === 0) return;

    let simpDefinitionIndex = -1;
    let realDefinitionIndex = -1;
    let sentencesIndex = -1;
    let relevantKeywordsIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (
        parts[i].includes("Simplified Definition:") ||
        parts[i].includes("Simplified Explanation")
      ) {
        simpDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Definition")) {
        realDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Sentences")) {
        sentencesIndex = i + 1;
      }
      if (
        parts[i].includes("Relevant Keywords") ||
        parts[i].includes("Hashtags")
      ) {
        relevantKeywordsIndex = i + 1;
      }
    }

    if (simpDefinitionIndex !== -1) {
      setSimpDefinition(parts[simpDefinitionIndex]);
    }

    if (realDefinitionIndex !== -1) {
      setRealDefinition(parts[realDefinitionIndex]);
    }

    if (sentencesIndex !== -1) {
      const sentences = [];
      for (let i = sentencesIndex; i < parts.length; i++) {
        if (
          parts[i].includes("Relevant Keywords") ||
          parts[i].includes("Hashtags")
        ) {
          break;
        }
        sentences.push(parts[i]);
      }
      setExampleSentences(sentences);
    }

    if (relevantKeywordsIndex !== -1) {
      const hashtagsArray = [];
      for (let i = relevantKeywordsIndex; i < parts.length; i++) {
        const part = parts[i].split(" ");
        hashtagsArray.push(...part);
      }
      setHashtags(hashtagsArray);
    }
  }

  function resetVariables() {
    setSimpDefinition("");
    setRealDefinition("");
    setExampleSentences([]);
    setHashtags([]);
    setWordPhrase("");
  }

  const onSwipe = async (direction) => {
    setSwipedOut(true);

    let newDirection = "";
    if (direction === "right") {
      toast.success("Added to Deep Lexicon!"); // Use newDirection directly

      newDirection = "Deep";
    } else if (direction === "left") {
      toast.success("Added to Surface Lexicon!"); // Use newDirection directly

      newDirection = "Surface";
    }
    setLastDirection(newDirection); // Update state with new direction
    // toast.success("Added to " + newDirection + " Lexicon!"); // Use newDirection directly
    if (direction === "right") {
      await sendToDeep();
    } else if (direction === "left") {
      await sendToSurface();
    }

    resetVariables();
  };

  const onCardLeftScreen = (myIdentifier) => {
    // console.log(myIdentifier + " left the screen");
  };

  const [wordPhrase, setWordPhrase] = useState("");

  const fetchData = async () => {
    resetVariables();
    setSwipedOut(false);
    setLoading(true);
    setStarting(true);
    const inputLength = input.split(" ").length;
    if (inputLength >= 10) {
      setInput("");
      setLoading(false);
      setStarting(true);
      setOutput("Please enter a shorter phrase.");
    } else {
      try {
        const response = await axios.get(
          `${baseUrl}get_data?word_or_phrase=${input}`,
        );
        setOutput(response.data.output || response.data);
        // setOutput("This is a test");
        setWordPhrase(input);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    setInput("");
  };

  const outputRef = useRef(output);
  useEffect(() => {
    if (output.length > 1) {
      formatText();
    }
  }, [output]);

  const [user] = useAuthState(auth);

  const sendToSurface = async (e) => {
    // Replace the id with user.uid
    // e.preventDefault();
    const userRef = firestore.collection("users").doc(user.uid);
    const surfaceRef = userRef.collection("surface");

    await surfaceRef.add({
      WordPhrase: wordPhrase,
      output: output,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      points: 0,
    });

    updateSurfaceData();
  };

  function isValidResponse(response) {
    if (
      response.includes("Word/Phrase does not exist.") ||
      response.includes("Word/Phrase is considered harmful.")
    ) {
      return false;
    }
    return true;
  }

  const sendToDeep = async (e) => {
    // Replace the id with user.uid
    // e.preventDefault();
    const userRef = firestore.collection("users").doc(user.uid);
    const deepRef = userRef.collection("deep");

    await deepRef.add({
      WordPhrase: wordPhrase,
      output: output,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      points: 0,
    });

    updateDeepData();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      resetVariables();
      fetchData();
      event.target.blur();
    }
  };
  const [swipedOut, setSwipedOut] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-2xl lg:text-3xl" mode="ios">
            ARTICULATE
          </IonTitle>
          <IonButtons slot="start">
            <Link to="/">
              <SignOut />
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter Word/Phrase..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              className="input-field bg-white"
            />
            <button onClick={fetchData} className="fetch-button">
              Analyze
            </button>
          </div>
          {/* <div className="flex items-center justify-center">
            <TinderCard
              className="pressable card flex h-screen w-full justify-center bg-primary/50 text-center shadow-xl"
              onSwipe={onSwipe}
              onCardLeftScreen={() => onCardLeftScreen("fooBar")}
              preventSwipe={["down", "up"]}
            >
              Hello, World!
            </TinderCard>
          </div> */}

          {!loading && !starting ? (
            <div></div>
          ) : (
            <>
              {loading ? (
                <div className="loading-container">
                  <div className="spinner">
                    <div className="spinner1"></div>
                  </div>
                </div>
              ) : Array.isArray(output) && isValidResponse(output) ? (
                <div className="output-text">
                  {swipedOut ? (
                    <div></div>
                  ) : (
                    <div className="relative mb-3 flex flex-row justify-evenly text-base-content">
                      <span className="absolute -bottom-1 left-1">Surface</span>

                      <svg
                        width="18"
                        height="8"
                        viewBox="0 0 18 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25 4.25L17.25 4.25"
                          stroke="gray"
                          stroke-width="1.00088"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.25 4.25003L16.75 4.25003"
                          stroke="gray"
                          stroke-width="1.00088"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.75 1.25C2.57843 2.42157 1.92157 3.07843 0.750001 4.25L3.75 7.25"
                          stroke="gray"
                          stroke-width="1.00088"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span className="absolute -bottom-1">Swipe</span>
                      <svg
                        width="17"
                        height="8"
                        viewBox="0 0 17 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.75 4.25003L0.250008 4.25003"
                          stroke="gray"
                          stroke-width="1.00088"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.25 1.25C14.4216 2.42157 15.0784 3.07843 16.25 4.25L13.25 7.25"
                          stroke="gray"
                          stroke-width="1.00088"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="absolute -bottom-1 right-2">Deep</span>
                    </div>
                  )}

                  <TinderCard
                    id="tinder-card"
                    className="pressable max-h-fit"
                    onSwipe={onSwipe}
                    onCardLeftScreen={() => onCardLeftScreen("fooBar")}
                    preventSwipe={["down", "up"]}
                    swipeRequirementType="position"
                    swipeThreshold={150}
                  >
                    <div className="card w-auto bg-primary/20 shadow-xl">
                      <div className="card-body">
                        <h2 className="mb-1 mt-1 text-center text-3xl">
                          {wordPhrase}
                        </h2>
                        <ul className="-mt-6">
                          <li>
                            <h5>Simplified Definition:</h5>
                            <p>{simpDefinition}</p>
                          </li>
                          <li>
                            <h5>Formal Definition:</h5> <p>{realDefinition}</p>
                          </li>
                          <li>
                            <h5>Sentences:</h5>
                            <ol>
                              {exampleSentences.map((sentence, index) => {
                                return (
                                  <li className="ml-5 list-decimal" key={index}>
                                    {sentence}
                                  </li>
                                );
                              })}
                            </ol>
                          </li>
                          <li>
                            <h5>Relevant Keywords:</h5>
                          </li>
                        </ul>
                        <div className="card-actions justify-center">
                          {hashtags.map((hashtag, index) => (
                            <div key={index} class="badge badge-outline">
                              {hashtag}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </TinderCard>
                  <Toaster
                    position="bottom-center"
                    toastOptions={{
                      duration: 2000,
                      style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        marginBottom: "12vh",
                        color: "#713200",
                      },
                    }}
                  ></Toaster>
                  {/* <div className="lexButtons">
                    <LexButton
                      id="open-surface"
                      onClick={sendToSurface}
                      children="Surface"
                    ></LexButton>
                    <LexButton
                      id="open-deep"
                      onClick={sendToDeep}
                      children="Deep"
                    ></LexButton>
                  </div> */}
                </div>
              ) : (
                <>
                  <div className="output-container">
                    <p className="output-text">{output}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div ref={outputRef} className="h-28 lg:h-16"></div>
      </IonContent>
    </IonPage>
  );
}

export default Search;
