import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import SignIn from "./components/auth/SignIn";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
import "firebase/compat/analytics";
import { Suspense, lazy } from "react";

import GuestPage from "./components/GuestPage";

import { onAuthStateChanged } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import HomePage from "./components/HomePage/HomePage";
import { GlobalDataProvider } from "./components/GlobalDataProvider";
import { IonApp } from "@ionic/react";
import HeroPage from "./components/HeroPage";
import { requestPermission } from "./components/firebase";

firebase.initializeApp({
  apiKey: "AIzaSyA-xdAlekKiUGoqwODJx1cdzmY0QW9bwuE",
  authDomain: "articulate-7c0c6.firebaseapp.com",
  projectId: "articulate-7c0c6",
  storageBucket: "articulate-7c0c6.appspot.com",
  messagingSenderId: "840986749146",
  appId: "1:840986749146:web:8aef54503d4313be399de3",
  measurementId: "G-1JY0V1M794",
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

firestore.enablePersistence({ synchronizeTabs: true }).catch((err) => {
  if (err.code === "failed-precondition") {
    console.warn(
      "Persistence failed: Multiple tabs open. Falling back to memory-only persistence.",
    );
  } else if (err.code === "unimplemented") {
    console.error("Persistence failed: not available in this browser");
  }
});

function App() {
  // const [user] = useAuthState(auth);
  // requestPermission(); // can't implement till i get apple dev acc
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div class="pyramid-loader">
          <div class="wrapper">
            <span class="side side1"></span>
            <span class="side side2"></span>
            <span class="side side3"></span>
            <span class="side side4"></span>
            <span class="shadow"></span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <IonApp>
      <div
        style={{
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <div className="App">
                  {user ? (
                    <GlobalDataProvider>
                      <HomePage />
                    </GlobalDataProvider>
                  ) : (
                    <HeroPage />
                  )}
                </div>
              )}
            />
            <Route path="/guest" exact>
              <GuestPage key="1"></GuestPage>
            </Route>
          </Switch>
        </Router>
      </div>
    </IonApp>
  );
}

export default App;
