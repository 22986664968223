import React from "react";
import greenCheck from "../assets/greenCheck.svg";
import redX from "../assets/redX.svg";
import whyEquation from "../assets/whyEquation.png";

function Why() {
  return (
    <>
      <div id="why-div" className="pt-20"></div>
      <div
        id="background-info"
        className="background-info bg-content h-screen px-10 lg:px-0"
      >
        <h2 className="-mt-1 text-5xl">Why Articulate?</h2>
        <div className=" overflow-x-scroll">
          <table className="table w-full ">
            {/* head */}
            <thead>
              <tr>
                <th className="lg:min-w-[150px]"></th>
                <th className="min-w-[50px] text-center lg:min-w-[150px]">
                  Articulate
                </th>
                <th className="min-w-[50px] text-center lg:min-w-[150px]">
                  Duolingo
                </th>
                <th className="min-w-[50px] text-center lg:min-w-[150px]">
                  Dictionary.com
                </th>
                <th className="min-w-[50px] text-center  lg:min-w-[150px]">
                  Quizlet
                </th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              <tr>
                <th className="text-left">Track Progress</th>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
              </tr>
              {/* row 2 */}
              <tr>
                <th className="text-left">Personalized Learning</th>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
              </tr>
              {/* row 3 */}
              <tr>
                <th className="text-left">AI Feedback</th>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
              </tr>
              {/* row 4 */}
              <tr>
                <th className="text-left">Gamification</th>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
              </tr>
              {/* row 5 */}
              <tr>
                <th className="text-left">Interactive Examples</th>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={greenCheck} alt="Yes" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center">
                    {" "}
                    {/* Flexbox to center content */}
                    <img src={redX} alt="No" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <img src={whyEquation} />
        </div>
      </div>
    </>
  );
}

export default Why;
