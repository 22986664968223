import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import React, { useState } from "react";
import { Brain, Loader2, Sparkles, Speech } from "lucide-react";
import ThoughtInput from "./components/ThoughtInput";
import SettingSelector from "./components/SettingSelector";
import ResultDisplay from "./components/ResultDisplay";
import Toast from "./components/Toast";
import axios from "axios";
import { useGlobalData } from "../GlobalDataProvider";

const Explore = () => {
  const [thought, setThought] = useState("");
  const [setting, setSetting] = useState("articulate");
  const [isProcessing, setIsProcessing] = useState(false);
  const [result, setResult] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { surfaceData, deepData } = useGlobalData();
  const formatLexicon = (surfaceData, deepData) => {
    const lexicon = {};

    // Process surfaceData
    surfaceData.forEach((item) => {
      const word = item.WordPhrase;
      const output = item.output;

      const definitionIndex = output.findIndex((item) =>
        /Definition:\s*/i.test(item),
      );
      const definition =
        definitionIndex !== -1 && output[definitionIndex + 1]
          ? output[definitionIndex + 1]
          : "Definition not found";

      lexicon[word] = definition;
    });

    // Process deepData
    deepData.forEach((item) => {
      const word = item.WordPhrase;
      const output = item.output;

      const definitionIndex = output.findIndex((item) =>
        /Definition:\s*/i.test(item),
      );
      const definition =
        definitionIndex !== -1 && output[definitionIndex + 1]
          ? output[definitionIndex + 1]
          : "Definition not found";

      lexicon[word] = definition;
    });

    return lexicon;
  };

  // Update the lexicon call
  const lexicon = formatLexicon(surfaceData, deepData);
  console.log(lexicon);
  const [toast, setToast] = useState({
    show: false,
    message: "",
    type: "success",
  });
  const processThought = async () => {
    if (!thought.trim() || thought.trim().split(" ").length < 5) {
      setToast({
        show: true,
        message: "Please enter at least 5 words for your thoughts",
        type: "error",
      });
      setTimeout(
        () => setToast({ show: false, message: "", type: "success" }),
        3000,
      );
      return;
    }

    setIsProcessing(true);
    // Construct the payload for the POST request
    const payload = {
      thought,
      setting,
      lexicon,
    };
    try {
      const response = await axios.post(`${baseUrl}refine_thought`, payload);
      setResult(response.data.join(" ") || "No output returned.");
    } catch (error) {
      console.error("Error fetching data:", error);
      setToast({
        show: true,
        message: "Error processing your thought. Please try again.",
        type: "error",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-2xl lg:text-3xl">
            <div className="flex items-center justify-center gap-2">
              <Brain className="h-8 w-8 text-blue-300 md:h-10 md:w-10" />
              Articulator
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="min-h-full bg-gradient-to-br from-violet-900/80 via-blue-900/80 to-purple-900/80 px-4 py-6 pb-28 md:p-8">
          <div className="mx-auto max-w-xl space-y-6">
            {/* Header */}
            <div className="space-y-3 text-center">
              <p className="text-sm text-blue-100 opacity-80 md:text-base">
                Transform your thoughts into refined expressions
              </p>
            </div>

            {/* Main Content */}
            <div className="space-y-5 ">
              <ThoughtInput value={thought} onChange={setThought} />

              <SettingSelector value={setting} onChange={setSetting} />

              <button
                onClick={processThought}
                disabled={isProcessing}
                className="flex w-full touch-none items-center justify-center gap-2 rounded-2xl bg-gradient-to-r 
                         from-blue-500 via-purple-500 to-pink-500 px-6 py-4 text-lg
                         font-semibold text-white transition-all
                         duration-300 hover:shadow-[0_0_20px_rgba(167,139,250,0.5)] active:scale-[0.98] disabled:opacity-50 disabled:active:scale-100"
              >
                {isProcessing ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Sparkles className="h-5 w-5" />
                )}
                {isProcessing ? "Processing..." : "Refine My Thought"}
              </button>

              {result && <ResultDisplay result={result} />}
            </div>
          </div>

          {toast.show && <Toast message={toast.message} type={toast.type} />}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Explore;
