import React from "react";

function ProductHunt() {
  return (
    <div className="fixed bottom-5 left-6">
      <a
        href="https://www.producthunt.com/posts/articulate?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-articulate"
        target="_blank"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=468100&theme=neutral"
          alt="Articulate - Redefine&#0032;How&#0032;You&#0032;Learn&#0032;Words | Product Hunt"
          className="h-[54px] w-[250px] opacity-80"
        />
      </a>
    </div>
  );
}

export default ProductHunt;
