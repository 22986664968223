import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { arrowBack } from "ionicons/icons";

import "../App.css";
import {
  IonContent,
  IonHeader,
  IonButton,
  IonButtons,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
} from "@ionic/react";

import "firebase/compat/auth";
import "firebase/compat/firestore";

import SignOut from "./auth/SignOut";

import { Link } from "react-router-dom/cjs/react-router-dom.min";

function GuestPage() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [starting, setStarting] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [simpDefinition, setSimpDefinition] = useState("");
  const [realDefinition, setRealDefinition] = useState("");
  const [exampleSentences, setExampleSentences] = useState([]);
  const [hashtags, setHashtags] = useState([]);

  function formatText() {
    const parts = output.map((sentence) =>
      sentence.replace(/\*|\n/g, "").trim(),
    );

    if (parts.length === 0) return;

    let simpDefinitionIndex = -1;
    let realDefinitionIndex = -1;
    let sentencesIndex = -1;
    let relevantKeywordsIndex = -1;

    // Find indices of different sections
    for (let i = 0; i < parts.length; i++) {
      if (
        parts[i].includes("Simplified Definition:") ||
        parts[i].includes("Simplified Explanation")
      ) {
        simpDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Definition")) {
        realDefinitionIndex = i + 1;
      }
      if (parts[i].includes("Sentences")) {
        sentencesIndex = i + 1;
      }
      if (
        parts[i].includes("Relevant Keywords") ||
        parts[i].includes("Hashtags")
      ) {
        relevantKeywordsIndex = i + 1;
      }
    }

    if (simpDefinitionIndex !== -1) {
      setSimpDefinition(parts[simpDefinitionIndex]);
    }

    if (realDefinitionIndex !== -1) {
      setRealDefinition(parts[realDefinitionIndex]);
    }

    if (sentencesIndex !== -1) {
      const sentences = [];
      for (let i = sentencesIndex; i < parts.length; i++) {
        if (
          parts[i].includes("Relevant Keywords") ||
          parts[i].includes("Hashtags")
        ) {
          break;
        }
        sentences.push(parts[i]);
      }
      setExampleSentences(sentences);
    }

    if (relevantKeywordsIndex !== -1) {
      const hashtagsArray = [];
      for (let i = relevantKeywordsIndex; i < parts.length; i++) {
        const part = parts[i].split(" ");
        hashtagsArray.push(...part);
      }
      setHashtags(hashtagsArray);
    }
  }

  function isValidResponse(response) {
    if (
      response.includes("Word/Phrase does not exist.") ||
      response.includes("Word/Phrase is considered harmful.")
    ) {
      return false;
    }
    return true;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      resetVariables();
      fetchData();
      event.target.blur();
    }
  };

  function resetVariables() {
    setSimpDefinition("");
    setRealDefinition("");
    setExampleSentences([]);
    setHashtags([]);
  }

  const fetchData = async () => {
    resetVariables();

    setLoading(true);
    setStarting(true);
    const inputLength = input.split(" ").length;
    if (inputLength >= 10) {
      setInput("");
      setLoading(false);
      setStarting(true);
      setOutput("Please enter a shorter phrase.");
    } else {
      try {
        const response = await axios.get(
          `${baseUrl}get_data?word_or_phrase=${input}`,
        );
        setOutput(response.data.output || response.data);
        // setOutput("This is a test");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (output.length > 1) {
      formatText();
    }
  }, [output]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="app-title" mode="ios">
            ARTICULATE
          </IonTitle>
          <IonButtons slot="start">
            <Link to="/">
              <IonIcon className="signout-icon ml-2 mt-2" icon={arrowBack} />
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter Word/Phrase..."
              value={input}
              onKeyDown={handleKeyDown}
              onChange={(e) => setInput(e.target.value)}
              className="input-field bg-white"
            />
            <button onClick={fetchData} className="fetch-button">
              Analyze
            </button>
          </div>

          {!loading && !starting ? (
            <div></div>
          ) : (
            <>
              {loading ? (
                <div className="loading-container">
                  <div className="spinner">
                    <div className="spinner1"></div>
                  </div>
                </div>
              ) : Array.isArray(output) && isValidResponse(output) ? (
                <div className="output-text">
                  <div className=" card w-auto bg-primary/20 shadow-xl">
                    <div className="card-body">
                      <ul>
                        <li>
                          <h5>Simplified Definition:</h5>{" "}
                          <p>{simpDefinition}</p>
                        </li>
                        <li>
                          <h5>Formal Definition:</h5> <p>{realDefinition}</p>
                        </li>
                        <li>
                          <h5>Sentences:</h5>
                          <ol>
                            {exampleSentences.map((sentence, index) => {
                              return (
                                <li className="ml-5 list-decimal" key={index}>
                                  {sentence}
                                </li>
                              );
                            })}
                          </ol>
                        </li>
                        <li>
                          <h5>Relevant Keywords:</h5>
                        </li>
                      </ul>
                      <div className="card-actions justify-center">
                        {hashtags.map((hashtag, index) => (
                          <div key={index} class="badge badge-outline">
                            {hashtag}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="output-container">
                    <p className="output-text">{output}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default GuestPage;
