import React from "react";
import { Brain, BookOpen, Speech } from "lucide-react";

const SettingSelector = ({ value, onChange }) => {
  const settings = [
    {
      id: "articulate",
      label: "Articulate",
      icon: Brain,
      description: "Clear and professional",
    },
    {
      id: "storytelling",
      label: "Storytelling",
      icon: BookOpen,
      description: "Narrative and engaging",
    },
    {
      id: "conversational",
      label: "Conversational",
      icon: Speech,
      description: "Casual and relaxed",
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
      {settings.map(({ id, label, icon: Icon, description }) => (
        <button
          key={id}
          onClick={() => onChange(id)}
          className={`flex touch-none flex-row items-center gap-3 rounded-2xl
                     border p-4 text-left backdrop-blur-md transition-all duration-300 active:scale-[0.98]
                     md:flex-col md:gap-2 md:text-center
                     ${
                       value === id
                         ? "border-white/30 bg-white/20 shadow-lg shadow-purple-500/20"
                         : "border-white/10 bg-white/10 hover:bg-white/15"
                     }`}
          style={{ WebkitTapHighlightColor: "transparent" }}
        >
          <Icon
            className={`h-8 w-8 md:h-6 md:w-6 ${value === id ? "text-blue-300" : "text-blue-400/60"}`}
          />
          <div className="flex-1 md:flex-none">
            <div className="text-lg font-semibold text-white md:text-base">
              {label}
            </div>
            <div className="text-sm text-blue-200/70">{description}</div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default SettingSelector;
