import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

const GlobalDataContext = createContext();

export const useGlobalData = () => useContext(GlobalDataContext);

const auth = firebase.auth();
const firestore = firebase.firestore();

export const GlobalDataProvider = ({ children }) => {
  const [user] = useAuthState(auth);
  const [totalSurface, setTotalSurface] = useState(0);
  const [surfaceData, setSurfaceData] = useState([]);
  const [totalDeep, setTotalDeep] = useState(0);
  const [deepData, setDeepData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartIsLoading, setChartIsLoading] = useState(true);
  const [suggestedThreeWords, setSuggestedThreeWords] = useState([]);
  const [readCount, setReadCount] = useState(0); // New state to track reads

  const userRef = useMemo(
    () => (user ? firestore.collection("users").doc(user.uid) : null),
    [user],
  );

  const [chartData, setChartData] = useState([]);
  const fetchReviewData = async () => {
    if (!user?.uid) {
      return;
    }

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);

    try {
      const filledData = await fillDataFromDate(startDate, user.uid);
      setChartData(filledData);
    } catch (error) {
      console.error("Error fetching review data:", error);
      setChartData([]);
    } finally {
      setChartIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReviewData();
  }, [user]);

  const fillDataFromDate = async (startDate, userId) => {
    const db = getFirestore();
    const reviewsRef = collection(db, "users", userId, "review");
    const today = new Date();
    const filledData = [];

    let currentDate = new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate(),
      ),
    );
    const endDate = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()),
    );
    while (currentDate <= endDate) {
      const dateString = currentDate.toISOString().split("T")[0];
      const formattedDate = `${currentDate.getUTCMonth() + 1}/${currentDate.getUTCDate()}`;

      try {
        const docRef = doc(reviewsRef, dateString);
        const docSnap = await getDoc(docRef);
        // setReadCount((prev) => prev + 1); // Increment read counter

        filledData.push({
          date: formattedDate,
          count: docSnap.exists() ? docSnap.data().reviewCount || 0 : 0,
        });
      } catch (error) {
        console.error("Error fetching data for date:", dateString, error);
        filledData.push({
          date: formattedDate,
          count: 0,
        });
      }

      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    return filledData;
  };

  const fetchSurfaceData = async () => {
    if (userRef) {
      try {
        const surfaceRef = userRef
          .collection("surface")
          .orderBy("createdAt", "desc");

        // Fetch from cache
        const cacheSnapshot = await surfaceRef.get({ source: "cache" });

        if (!cacheSnapshot.empty) {
          const cachedData = cacheSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log("Surface data fetched from cache:", cachedData);
          setSurfaceData(cachedData);
          setTotalSurface(cachedData.length);
          // setReadCount((prev) => prev + cacheSnapshot.docs.length); // Increment read counter
          return; // Exit function if data was fetched from cache
        }

        // Fetch from server if cache is empty
        const serverSnapshot = await surfaceRef.get();
        const serverData = serverSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log("Surface data fetched from server:", serverData);
        setSurfaceData(serverData);
        setTotalSurface(serverData.length);
        // setReadCount((prev) => prev + serverSnapshot.docs.length); // Increment read counter
      } catch (error) {
        console.error("Error fetching surface data: ", error);
        // Handle specific errors, log, or provide user feedback
      }
    }
  };

  const [surfaceDataAlphabetical, setSurfaceDataAlphabetical] = useState([]);

  useEffect(() => {
    sortSurfaceAlphabetically();
    calculateBadges();
  }, [surfaceData]);

  function sortSurfaceAlphabetically() {
    const sortedSurfaceData = [...surfaceData].sort((a, b) => {
      return a["WordPhrase"].localeCompare(b["WordPhrase"]);
    });
    setSurfaceDataAlphabetical(sortedSurfaceData);
  }

  const [bronzeBadges, setBronzeBadges] = useState(0);
  const [silverBadges, setSilverBadges] = useState(0);
  const [goldBadges, setGoldBadges] = useState(0);
  const [trophyBadges, setTrophyBadges] = useState(0);

  const calculateBadges = () => {
    const bronzeBadges = surfaceData.filter(
      (data) => data.points >= 10 && data.points < 30,
    ).length;
    const silverBadges = surfaceData.filter(
      (data) => data.points >= 30 && data.points < 50,
    ).length;
    const goldBadges = surfaceData.filter(
      (data) => data.points >= 50 && data.points < 70,
    ).length;
    const trophyBadges = surfaceData.filter((data) => data.points >= 70).length;

    setBronzeBadges(bronzeBadges);
    setSilverBadges(silverBadges);
    setGoldBadges(goldBadges);
    setTrophyBadges(trophyBadges);
  };

  const [deepDataAlphabetical, setDeepDataAlphabetical] = useState([]);
  useEffect(() => {
    sortDeepAlphabetically();
  }, [deepData]);
  function sortDeepAlphabetically() {
    const sortedDeepData = [...deepData].sort((a, b) => {
      return a["WordPhrase"].localeCompare(b["WordPhrase"]);
    });
    setDeepDataAlphabetical(sortedDeepData);
  }

  const fetchDeepData = async () => {
    if (userRef) {
      try {
        const deepRef = userRef.collection("deep").orderBy("createdAt", "desc");

        // Fetch from cache
        const cacheSnapshot = await deepRef.get({ source: "cache" });

        if (!cacheSnapshot.empty) {
          const cachedData = cacheSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log("Deep data fetched from cache:", cachedData);
          setDeepData(cachedData);
          setTotalDeep(cachedData.length);
          // setReadCount((prev) => prev + cacheSnapshot.docs.length); // Increment read counter
          return; // Exit function if data was fetched from cache
        }

        // Fetch from server if cache is empty
        const serverSnapshot = await deepRef.get();
        const serverData = serverSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log("Deep data fetched from server:", serverData);
        setDeepData(serverData);
        setTotalDeep(serverData.length);
        // setReadCount((prev) => prev + serverSnapshot.docs.length); // Increment read counter
      } catch (error) {
        console.error("Error fetching deep data: ", error);
        // Handle specific errors, log, or provide user feedback
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userRef) {
        setIsLoading(true);
        await Promise.all([fetchSurfaceData(), fetchDeepData()]);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userRef]);

  const updateSurfaceData = fetchSurfaceData;
  const updateDeepData = fetchDeepData;

  const [surfaceDataOrderByPoints, setSurfaceDataOrderByPoints] = useState([]);
  useEffect(() => {
    sortSurfaceOrderByPoints();
    fetchSuggestedThreeWordsLowestPoints();
  }, [surfaceData]);

  useEffect(() => {
    fetchSuggestedThreeWordsLowestPoints();
  }, [surfaceDataOrderByPoints]);

  function sortSurfaceOrderByPoints() {
    const sortedPointsSurfaceData = [...surfaceData].sort((a, b) => {
      let first = a["points"] ? a["points"] : 0;
      let second = b["points"] ? b["points"] : 0;
      return second - first;
    });
    setSurfaceDataOrderByPoints(sortedPointsSurfaceData);
  }

  function fetchSuggestedThreeWordsLowestPoints() {
    const reversedSurfaceDataOrderByPoints = [
      ...surfaceDataOrderByPoints,
    ].reverse();
    const suggestedThreeWords = reversedSurfaceDataOrderByPoints.slice(0, 3);
    setSuggestedThreeWords(suggestedThreeWords);
  }

  return (
    <GlobalDataContext.Provider
      value={{
        totalSurface,
        surfaceData,
        updateSurfaceData,
        fetchSurfaceData,
        totalDeep,
        deepData,
        updateDeepData,
        isLoading,
        surfaceDataAlphabetical,
        deepDataAlphabetical,
        surfaceDataOrderByPoints,
        bronzeBadges,
        silverBadges,
        goldBadges,
        trophyBadges,
        chartData,
        fetchReviewData,
        chartIsLoading,
        suggestedThreeWords,
        fetchSuggestedThreeWordsLowestPoints,
        readCount, // Expose the read count to the context
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};
