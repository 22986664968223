import React from "react";
import HowItWorksAccordion from "../HomePage/howitworks-accordion";

function HowItWorks() {
  return (
    <>
      <div id="how-it-works-div" className="divider-info pt-8"></div>
      <div
        id="how-it-works"
        className="container mx-auto mt-3 flex max-w-3xl flex-col items-center px-10 lg:px-0"
      >
        <h2 className="mb-10 text-3xl md:text-5xl">How It Works</h2>
        <HowItWorksAccordion />
      </div>
    </>
  );
}

export default HowItWorks;
