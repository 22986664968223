import React from "react";
import { MessageSquareText } from "lucide-react";

const ThoughtInput = ({ value, onChange }) => {
  return (
    <div className="relative">
      <MessageSquareText className="absolute left-4 top-4 z-10 h-6 w-6 text-blue-300" />

      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter your thoughts here..."
        className="z-4 h-40 w-full resize-none rounded-2xl border border-white/20 bg-white/10 p-4
                   pl-14 text-base text-white placeholder-blue-200/50
                   shadow-xl backdrop-blur-md focus:outline-none
                   focus:ring-2 focus:ring-blue-400/50 md:h-48 md:text-lg"
        style={{ WebkitTapHighlightColor: "transparent" }}
      />
    </div>
  );
};

export default ThoughtInput;
