import React from "react";
import { AlertCircle, CheckCircle } from "lucide-react";

const Toast = ({ message, type }) => {
  const bgColor = type === "error" ? "bg-red-500/90" : "bg-green-500/90";
  const Icon = type === "error" ? AlertCircle : CheckCircle;

  return (
    <div className="animate-slide-down fixed left-4 right-4 top-20 z-20 md:left-auto md:right-4">
      <div
        className={`${bgColor} mx-auto flex max-w-md items-center justify-center 
                      gap-3 rounded-2xl px-4 py-4 text-base text-white
                      shadow-lg backdrop-blur-md md:mx-0 md:justify-start md:text-sm`}
      >
        <Icon className="h-5 w-5" />
        {message}
      </div>
    </div>
  );
};

export default Toast;
