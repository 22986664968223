import React from "react";

function Footer() {
  return (
    <footer className="min-h-50 footer footer-center mb-0 bg-base-300 p-4 text-base-content md:mb-0 md:pb-4">
      <aside>
        <p>Copyright © 2024 - All right reserved by Articulate</p>
      </aside>
    </footer>
  );
}

export default Footer;
