import React, { useState, useEffect } from "react";
import pwa from "./assets/pwa.png";

const InstallPWA = ({ className }) => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode = window.matchMedia(
      "(display-mode: standalone)",
    ).matches;

    if (isIOS && !isInStandaloneMode) {
      setSupportsPWA(true);
    }

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      // Show iOS instructions
      alert(
        "To install the app, tap the Share button in Chrome, scroll down, and then 'Add to Home Screen'",
      );
      return;
    }
    promptInstall.prompt();
  };

  if (!supportsPWA) {
    return null;
  }

  return (
    <div className={`flex items-center justify-center opacity-80 ${className}`}>
      <button onClick={onClick} aria-label="Install app" title="Install app">
        <img
          src={pwa}
          alt="Install PWA"
          className="mb-16 w-48 md:mb-0 lg:h-12 lg:w-auto"
        />
      </button>
    </div>
  );
};

export default InstallPWA;
